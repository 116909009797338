import React from "react";
import styled from "styled-components";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import facebookIcon from "../../../images/icon_facebook.svg";
import twitterIcon from "../../../images/icon_twitter.svg";
import linkedinIcon from "../../../images/icon_linkedin.svg";

const SocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: absolute;
  top: 0;
  left: 65%;
  transform: translateX(-65%);

  .social-icons {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const SideBarSocialIcons = ({ mobile }) => {
  const url = typeof window !== "undefined" ? window.location.href : "";

  return (
    <SocialIconsWrapper>
      <FacebookShareButton url={url}>
        <img src={facebookIcon} className="social-icons"></img>
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <img src={twitterIcon} className="social-icons"></img>
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <img src={linkedinIcon} className="social-icons"></img>
      </LinkedinShareButton>
    </SocialIconsWrapper>
  );
};

export default SideBarSocialIcons;
