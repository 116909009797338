import React from "react";
import NavBar from "./navbar";
import { createGlobalStyle } from "styled-components";
import "../styles/global.css";
import ContactPopUp from "./contactPopUp";
import { Component } from "react";
import MediaQuery from "react-responsive";
import MobileNav from "./mobile/mobileNav";
import "@fontsource/rubik";
import "@fontsource/rubik/700.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/400.css";
import $ from "jquery";

const GlobalStyles = createGlobalStyle`
  html {
    --accent: #ef258f;
    --accent-2: #5e3dd2;
    --text: #190949;
    --blue: #5468f4;
    --green: #5dbdba;
    --red: #f75c73;
    --ultramarine: #3600b8;
    --cobalt: #392180;
    --pastel-blue: #a9b4ff;
    --mango: #ffa22b;
    --background2: #f7f8ff;
    --black: #080708;

    display: table;
    width: 100%;
    max-width: 100%;
    //scroll-behavior: smooth;
    height: 100%;
  }

  ::-moz-placeholder {
  opacity: 1;
    }

  #___gatsby{
    height: 100%;
  }  

  #gatsby-focus-wrapper{
    height: 100%;
  }

  body {
    margin: 0;
    min-height: 100%;
    width: 100%;
    max-width: 100%;
    display: table-cell;
    font-family: "Rubik", sans-serif;
    font-display: swap;
    overflow-x: hidden;

  }

  p{
    color: var(--text);
    font-size: 1.6rem;
  }

  h1 {
    font-size: 4rem;
    font-weight: normal;
    margin: 0;
    color: var(--text);
  }

  h2{
    font-size: 4rem;
    font-weight: 500;
    color: var(--text);
    margin: 16px 0 0 0;
  }

  h3{
    font-size: 2.4rem;
    font-weight: normal;
    margin: 0;
    color: var(--blue);
  }
`;

const isBrowser = () => typeof window !== "undefined";

function disableScroll() {
  if ($(document).height() > $(window).height()) {
    // var scrollTop = $("html").scrollTop()
    //   ? $("html").scrollTop()
    //   : $("body").scrollTop(); // Works for Chrome, Firefox, IE...
    // $("html").addClass("noscroll").css("top", -scrollTop);
    $("html").addClass("noscroll");
  }
}

function enableScroll() {
  // var scrollTop = parseInt($("html").css("top"));
  $("html").removeClass("noscroll");
  // $("html,body").scrollTop(-scrollTop);
}

function initContactPopUp() {
  if (isBrowser) {
    var popUp = document.getElementById("popUpContainer");
    var popUpContent = document.getElementById("popUpContent");
    var buttons = Array.from(document.getElementsByClassName("contact"));
    // Get the <span> element that closes the modal
    var span = document.getElementById("closePopUp");
    // When the user clicks on the button, open the modal
    buttons.map(
      (btn) =>
        (btn.onclick = function () {
          if (btn.classList.contains("mobile")) {
            popUpContent.style.width = "75%";
            popUpContent.style.padding = "1.8rem";
          }
          popUp.style.display = "flex";
          //popUpContent.style.width = "50rem";
          disableScroll();
        })
    );
    // When the user clicks on <span> (x), close the modal
    span.addEventListener("click", function () {
      popUp.style.display = "none";
      enableScroll();
    });
  }
  // When the user clicks anywhere outside of the modal, close it
  window.addEventListener("click", function (event) {
    if (event.target === popUp) {
      popUp.style.display = "none";
      enableScroll();
    }
  });
}

class Layout extends Component {
  componentDidMount() {
    initContactPopUp();
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <GlobalStyles />
        <MediaQuery minWidth={450}>
          <NavBar />
          {this.props.children}
          <ContactPopUp />
        </MediaQuery>
        <MediaQuery maxWidth={450}>
          <MobileNav />
          {this.props.children}
          <ContactPopUp />
        </MediaQuery>
      </div>
    );
  }
}

export default Layout;
