import { Link } from "gatsby";
import * as React from "react";
import { Component } from "react";
import styled from "styled-components";
import "../../components/font-awesome";
import SideBarSocialIcons from "../homePage/sidebar/sidebar-social-icons";

const MenuWrapper = styled.div`
  width: 100%;
  //height: calc(100vh - 8rem);
  height: 0;
  overflow: hidden;
  position: relative;
  top: 80px;
  display: flex;

  transition: 0.5s;
`;

const ContentWrapper = styled.div`
  width: 70%;
  padding-top: 3.6rem;
`;

const SocialLinksWrapper = styled.div`
  width: 20%;
  height: 20vh;
  right: 0;
  top: 35%;
  transform: translateY(-35%);
  position: absolute;
`;

const MenuItem = styled.div`
  margin-bottom: 3.6rem;
  display: flex;
  flex-direction: column;

  .nav-item {
    font-size: 1.6rem;
    margin: 1.6rem 0;
    padding-left: ${(props) =>
      props.$active ? "calc(2.4rem + 16px)" : "calc(2.4rem + 8px)"};
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
  }

  .active {
    color: var(--accent);
  }
`;

const Title = styled((props) => <Link {...props} />)`
  text-decoration: none;
  font-size: 2.8rem;
  border-left: ${(props) =>
    props.$active ? "16px solid var(--accent)" : "8px solid var(--black);"};
  padding-left: 2.4rem;
  cursor: pointer;
  color: ${(props) => (props.$active ? "var(--accent)" : "var(--black);")};
`;

const HomeLink = styled((props) => <Link {...props} />)`
  text-decoration: none;

  p {
    margin: 0;
  }
`;

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.activeLink = this.activeLink.bind(this);
    this.state = {
      active: "home",
    };
  }

  componentDidMount() {
    this.activeLink();
  }

  activeLink() {
    const isBrowser = () => typeof window !== "undefined";
    if (isBrowser) {
      var url = window.location.href.split("/");
      var activeUrl = url[3];
      if (activeUrl === "") this.setState({ active: "home" });
      if (activeUrl.startsWith("news")) this.setState({ active: "news" });
      if (activeUrl.startsWith("legislation"))
        this.setState({ active: "legislation" });
    }
  }

  render() {
    return (
      <MenuWrapper id="menu">
        <ContentWrapper>
          <MenuItem $active={this.state.active === "home" ? true : false}>
            <Title to="/" $active={this.state.active === "home" ? true : false}>
              Home
            </Title>
            <HomeLink to="/#nearshore_content">
              <p className="nav-item nearshore_content">
                <strong>Nearshore.</strong> A jump in the era of digital
                transformation
              </p>
            </HomeLink>
            <HomeLink to="/#advantages_content">
              <p className="nav-item advantages_content">
                <strong>Advantages.</strong> Why choose this solution?
              </p>
            </HomeLink>
            <HomeLink to="/#whyportugal_content">
              <p className="nav-item whyportugal_content">
                <strong>Why Portugal?</strong> 5 reasons to choose Portugal
              </p>
            </HomeLink>
            <HomeLink to="/#areaofaction_content">
              <p className="nav-item areaofaction_content">
                <strong>Area of action.</strong> Which countries hire in
                Portugal and salary comparison
              </p>
            </HomeLink>
            <HomeLink to="/#news_content">
              <p className="nav-item news_content">
                <strong>Latest news</strong>
              </p>
            </HomeLink>
          </MenuItem>
          <MenuItem>
            <Title
              to="/news"
              $active={this.state.active === "news" ? true : false}
            >
              News
            </Title>
          </MenuItem>
          <MenuItem>
            <Title
              to="/legislation"
              $active={this.state.active === "legislation" ? true : false}
            >
              Legislation
            </Title>
          </MenuItem>
        </ContentWrapper>
        <SocialLinksWrapper>
          <SideBarSocialIcons mobile="true" />
        </SocialLinksWrapper>
      </MenuWrapper>
    );
  }
}

export default MobileMenu;
