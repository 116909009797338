import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Component } from "react";
import styled from "styled-components";
import Button from "./button";

const NavWrapper = styled.div`
  height: 8rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 9vw;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  position: fixed;
  z-index: 5;
  transition: top 0.3s;
  top: 0;
`;

const StyledLink = styled((props) => <Link {...props} />)`
  color: ${(props) => (props.active ? "var(--accent)" : "var(--text)")};
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 500;
  margin-left: 3.6rem;
`;

const NavLinksWrappper = styled.div`
  display: flex;
  align-items: center;

  .contact {
    margin-left: 3.6rem;
  }
`;

const NavBrand = styled((props) => <Link {...props} />)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;

  p {
    font-size: 2rem;
    font-weight: 500;
    color: var(--text);
    margin-left: 1.6rem;
  }
`;

const LogoWrapper = styled.div`
  width: 4rem;
`;

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.changeLink = this.changeLink.bind(this);
    this.state = {
      linkActive: "",
    };
  }

  componentDidMount() {
    this.changeLink();
  }

  changeLink() {
    const isBrowser = () => typeof window !== "undefined";
    if (isBrowser()) {
      var url = window.location.href;
      if (url.includes("legislation")) {
        this.setState({
          linkActive: "legislation",
        });
      }
      if (url.includes("news")) {
        this.setState({
          linkActive: "news",
        });
      }
    }
  }

  render() {
    return (
      <NavWrapper className="navbar">
        <NavBrand to="/">
          <LogoWrapper>
            <StaticImage
              src="../images/img-logo-big@3x.webp"
              alt="logo"
              className="logo"
              placeholder="blurred"
            ></StaticImage>
          </LogoWrapper>
          <p>Nearshore.pt</p>
        </NavBrand>
        <NavLinksWrappper>
          <StyledLink
            to="/news"
            active={this.state.linkActive === "news" ? true : false}
          >
            News
          </StyledLink>
          <StyledLink
            to="/legislation/"
            active={this.state.linkActive === "legislation" ? true : false}
          >
            Legislation
          </StyledLink>
          <Button
            content="Contact Us"
            font="1.8rem"
            bg="--accent-2"
            className="contact"
          />
        </NavLinksWrappper>
      </NavWrapper>
    );
  }
}

export default NavBar;
