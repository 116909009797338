import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import emailjs from "emailjs-com";
import ContactIcon from "../images/contact-icon-white.svg";

const PopUpContainer = styled.div`
  display: none;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  align-items: center;
  justify-content: center;
`;

const PopUpContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 50rem;
  height: fit-content;
  position: absolute;
  border-radius: 16px;
  padding: 3.8rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);

  h3 {
    font-size: 2.8rem;
    font-weight: bold;
    color: var(--ultramarine);
    margin: 1.6rem 0 3.6rem 0;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #closePopUp {
    position: absolute;
    right: 0;
    top: 0;
    margin: 1.6rem;
    cursor: pointer;
  }

  .sendBtn {
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: "0.8rem 0",
    },
  },
  palette: {
    primary: {
      main: "#5468f4",
    },
    secondary: {
      main: "#ef258f",
    },
  },
  customTextField: {
    fontSize: "2rem",
    fontFamily: "Rubik",
  },
  customMultiline: {
    fontSize: "2rem",
    lineHeight: "1",
    fontFamily: "Rubik",
  },
  inputFocused: {},
  labelRoot: {
    fontSize: "1.6rem",
    fontFamily: "Rubik",
  },
}));

const SendButton = styled.div`
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  border-radius: 23px;
  background-color: var(--accent);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.3rem 0 0 0;
  white-space: nowrap;

  img{
    width: 2.2rem;
    margin-right: 5px;
  }

  input {
    border: none;
    cursor: pointer;
    background-color: var(--accent);
    color: white;
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

const ContactPopUp = () => {
  const classes = useStyles();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qbj9mkh",
        "template_n813jng",
        e.target,
        "user_jaUkPrmCIZZTB0iLavEPa"
      )
      .then(
        (result) => {
          //popup enviado com sucesso
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <PopUpContainer id="popUpContainer">
      <PopUpContent id="popUpContent">
        <h3>Let's talk</h3>
        <FontAwesomeIcon icon="times" size="2x" id="closePopUp" />
        <form className={classes.root} onSubmit={sendEmail}>
          <div className="group">
            <TextField
              label="Name"
              name="name"
              required
              fullWidth
              color="primary"
              InputProps={{
                classes: {
                  input: classes.customTextField,
                  focused: classes.inputFocused,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
            />
            <TextField
              label="Email"
              name="email"
              required
              fullWidth
              InputProps={{
                classes: {
                  input: classes.customTextField,
                  focused: classes.inputFocused,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
            />
            <TextField
              label="Company Name (optional)"
              name="company"
              fullWidth
              InputProps={{
                classes: {
                  input: classes.customTextField,
                  focused: classes.inputFocused,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
            />
            <TextField
              label="Subject"
              name="subject"
              required
              fullWidth
              InputProps={{
                classes: {
                  input: classes.customTextField,
                  focused: classes.inputFocused,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
            />
            <TextField
              label="Message"
              name="message"
              required
              multiline
              fullWidth
              rows={3}
              InputProps={{
                classes: {
                  input: classes.customMultiline,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
            />
          </div>
          <SendButton>
            <img src={ContactIcon} alt="Contact us icon"></img>
            <input type="submit" className="sendBtn" value="Send"></input>
          </SendButton>
        </form>
      </PopUpContent>
    </PopUpContainer>
  );
};

export default ContactPopUp;
