import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import MenuIcon from "../../images/icon-menu.svg";
import CloseIcon from "../../images/icon-close.svg";
import ContactIcon from "../../images/contact-icon.svg";
import { Component } from "react";
import MobileMenu from "./mobileMenu";

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const NavWrapper = styled.div`
  height: 8rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4.8rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  position: fixed;
  z-index: 5;
  transition: top 0.3s;
  top: 0;
`;

const NavBrand = styled((props) => <Link {...props} />)`
  text-decoration: none;

  p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text);
  }
`;

const Icon = styled.div`
  cursor: pointer;
  transition-duration: 1s;
`;

const isBrowser = () => typeof window !== "undefined";

class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.state = {
      menuIsOpened: false,
    };
  }

  componentDidMount() {
    var mobileNavItems = Array.from(
      document.getElementsByClassName("nav-item")
    );
    mobileNavItems.map((e) =>
      e.addEventListener("click", this.handleMenuClick)
    );
  }

  handleMenuClick() {
    if (isBrowser) {
      var menu = document.getElementById("menu");
      var mainContent = Array.from(
        document.getElementsByClassName("main_content")
      );
      if (document.getElementById("mobile_footer")) {
        var mobileFooter = document.getElementById("mobile_footer");
      }

      if (this.state.menuIsOpened === false) {
        menu.style.height = "calc(100vh - 8rem)";
        mainContent.map((el) => (el.style.display = "none"));
        if (document.getElementById("mobile_footer")) {
          mobileFooter.style.display = "none";
        }
        document.getElementById("menuIconImg").src = CloseIcon;
        this.setState({
          menuIsOpened: true,
        });
      }

      if (this.state.menuIsOpened) {
        menu.style.height = "0";
        mainContent.map((el) => (el.style.display = "block"));
        if (document.getElementById("mobile_footer")) {
          mobileFooter.style.display = "flex";
        }
        document.getElementById("menuIconImg").src = MenuIcon;
        this.setState({
          menuIsOpened: false,
        });
      }
    }
  }

  render() {
    return (
      <Nav className="mobileNav">
        <NavWrapper className="navbar" id="mobileNav">
          <Icon id="menuIcon" onClick={this.handleMenuClick}>
            <img src={MenuIcon} alt="Menu icon" id="menuIconImg"></img>
          </Icon>
          <NavBrand to="/">
            <p>Nearshore.pt</p>
          </NavBrand>
          <Icon className="contact mobile">
            <img src={ContactIcon} alt="Contact us icon"></img>
          </Icon>
        </NavWrapper>
        <MobileMenu />
      </Nav>
    );
  }
}

export default MobileNav;
