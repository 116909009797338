import React from "react";
import styled from "styled-components";

const StyleButton = styled.button(
  (props) => `
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  border-radius: 23px;
  background-color: var(${props.bg});
  color: white;
  border: none;
  font-size: ${props.font};
  font-weight: 500;
  width: fit-content;
  margin: ${props.margin};
  white-space: nowrap;
`);

const Button = ({content, font, margin, bg, className}) => {
  return (
    <StyleButton font={font} margin={margin} bg={bg} className={className}>{content}</StyleButton>
  );
};

export default Button;